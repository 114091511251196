
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【プレミアムカー】中古車（MT・AT）の販売店を選ぶ際に押さえるべき7つのポイント
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            プレミアムカーの中古車を購入する前に、信頼できる販売店を探すのが鉄則です。中古車販売店を選ぶときは、口コミや保証制度の有無、取り扱う車種、スタッフの対応、展示車の状態、値引きに対応しているかなどを確認しましょう。
            <div class="section-title">
              <span >中古車（MT・AT）はどこで購入する？販売店の選び方</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      信頼できる中古車販売店を選ぶためには、押さえるべきポイントがいくつかあります。
                    </p>
                    <p class="u-m0 u-pb1em">
                      良質な販売店を選ぶためのポイントは、次の7つです。
                    </p>
                    <h3 class="heading-number" data-number="◆">評判と信頼性</h3>
                    <p class="u-m0 u-pb1em">
                      信頼できる販売店は、顧客対応はもちろん、保証などもしっかりとしています。インターネットのレビューサイトや口コミをチェックして、実際に利用された方のリアルな感想を確認してください。
                    </p>
                    <h3 class="heading-number" data-number="◆">保証制度が充実しているか</h3>
                    <p class="u-m0 u-pb1em">
                      中古車の点検や整備を適切に行っている場合は、購入後すぐに修理が必要となるケースはほとんどありません。しかし、定期的に消耗部品の交換は必要であるうえ、長く乗っていればどこかの部品に不具合が発生します。
                    </p>
                    <p class="u-m0 u-pb1em">
                      中古車の購入を成功させたいのでしたら、自身に必要な保証サービスがその販売店で提供されているのかを確認しましょう。販売店が提供するサービスの保証期間や購入後のアフターケア、そして整備体制など、販売店のサービスを把握し、自分なりに評価してみましょう。
                    </p>
                    <p class="u-m0 u-pb1em">
                      また、車の安全性や品質を保証するための鑑定書がついているかも併せて確認してみてください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-maker-mt/used-car-store-choose.jpg" alt="Image">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">取り扱う車種の豊富さ</h3>
              <p class="u-m0 u-pb1em">
                取り扱う車種が多ければ多いほど、自分の好みやニーズに合った車を見つけやすくなります。しかし、ホームページに取り扱いメーカーが記載されていたとしても、店舗に訪れたときには在庫がなかったというケースも考えられます。実際にどのような車種があるのか知りたい場合には、お店に問い合わせてみることをおすすめします。
              </p>
              <h3 class="heading-number" data-number="◆">スタッフの対応</h3>
              <p class="u-m0 u-pb1em">
                スタッフの対応も確認しましょう。顧客の希望にしっかりと寄り添って親切丁寧な対応をしてくれる業者に依頼すれば、自分の希望条件に合う中古車を購入できる可能性が高くなります。
              </p>
              <h3 class="heading-number" data-number="◆">中古車に試乗できるか</h3>
              <p class="u-m0 u-pb1em">
                試乗は、車の乗り心地や走行性を確認するために重要な判断材料となります。しっかりと整備した中古車を販売している業者の場合、売れる自信があるため試乗をすすめてくることもあります。
              </p>
              <h3 class="heading-number" data-number="◆">展示車の状態がよいのか</h3>
              <p class="u-m0 u-pb1em">
                展示車の状態は、販売店の品質管理の指標となります。展示されている車の外装やエンジンルーム、タイヤの状態などを細かく確認してください。
              </p>
              <h3 class="heading-number" data-number="◆">大幅な値引きをしないか</h3>
              <p class="u-m0 u-pb1em">
                大幅な値引きをする店舗は、適正な価格設定がなされていない可能性がありますので、注意が必要です。優良店では、理由もなく不用意に値引きすることはありません。値引きするということは、車の品質に何かしら問題があるケースも考えられます。
              </p>
              <p class="u-m0 u-mt20 u-pb1em">
                中古車の購入を検討している方は、ぜひcar shop MIXまでお気軽にご相談ください。国産車・外車を問わず、プレミアムカー(MT・AT)を中心に取り揃えています。低走行車や25年規制が解除されるような車などのご提供も可能です。
              </p>
              <p class="u-m0 u-pb1em">
                在庫一覧にお目当ての車種がない場合も、代行でお探しします。中古の高級車やレアな車種をお探しの方は、お気軽にお問い合わせください。
              </p>
              <div class="c-btn">
                <a class="c-btn-anchor" href="https://www.worldingsysdom.com/stock_info/list/car" >在庫一覧</a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >中古車販売店をお探しならcar shop MIXへ</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      数多くある中古車販売店の中から信頼できる店舗を探すのは、容易なことではありません。しかし、今回ご紹介した中古車販売店の選び方のポイントを押さえることで、最適な店舗を選べるでしょう。
                    </p>
                    <p class="u-m0 u-pb1em">
                      中古車販売店を選ぶときは、どうしてもリーズナブルな価格で販売している店舗を選びがちです。販売価格以外にも、保証制度の有無やスタッフの対応、試乗ができるのか、大幅な値引きが行われていないかといった点をしっかり確認したうえで購入する店舗を選ぶことをおすすめします。
                    </p>
                    <p class="u-m0 u-pb1em">
                      中古車の購入を検討されている方は、car shop MIXにお任せください。car shop MIXには、一般的に市場に出回らないような車種やモデルを集めるノウハウがございます。そのため、国産車だけでなく、外車など様々なメーカーの中古車を豊富に取り揃えているのが大きな強みです。
                    </p>
                    <p class="u-m0 u-pb1em">
                      サイト内で中古車の在庫をご確認いただけますが、万が一在庫がない場合でもお客様の代わりにオークションなどでご希望の車種をお探しいたします。ぜひお気軽にお問い合わせください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="車のミニチュアを持つ人物" src="@/assets/img/column-maker-mt/car-miniature-person.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/jigyo" class="c-btn-anchor">事業内容</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'プレミアムカー(MT・AT)をお探しなら中古車販売のcar shop MIXまでご相談ください'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-maker-mt/head-mv2-02.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}

</style>
